import { Api } from 'common/server';
import { Config } from 'services/config';

export class MemberFiles {
    static inject = [Api, Config];
    _api;
    _config;

    constructor(api, config) {
        this._api = api;
        this._config = config;
    }

    async list(memberId) {
        const url = memberId
            ? `member-file/${encodeURIComponent(memberId)}`
            : 'member-file';
        return await this._api.get(url);
    }

    fileTypes() {
        return this._config.value('memberFileTypes');
    }

    async delete(memberId, fileId) {
        return this._api.delete(`member-file/${encodeURIComponent(memberId)}/${encodeURIComponent(fileId)}`);
    }
}
