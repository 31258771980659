import { Api } from 'common/server';
import { I18n } from 'common/i18n';
import { Content } from 'services/content';
import { Page } from 'common/ui';

export const AGENT_SITE_TYPE = {
    Agent: 'agent',
    YourCalendarOnline: 'your-calendar-online',
    MyBizCardOnline: 'my-biz-card-online'
};

export class AgentSites {
    static inject = [Api, I18n, Content, Page];
    _api;
    _i18n;
    _content;
    _page;
    siteCode = null;
    site = null;

    constructor(api, i18n, content, page) {
        this._api = api;
        this._i18n = i18n;
        this._content = content;
        this._page = page;
    }

    validate() {
        return this.site;
    }

    agentName() {
        if (!this.site || !this.site.member) return '';
        return this.site.member.fullName;
    }

    profile() {
        return this.site.member;
    }

    bioImg() {
        return this.site.bioImg;
    }

    async status(siteType, memberId) {
        let url = `member-site/${encodeURIComponent(siteType)}/status`;
        if (memberId) url += `?memberId=${encodeURIComponent(memberId)}`;
        return await this._api.get(url);
    }

    async ssoUrl(siteType, memberId) {
        let url = `member-site/${encodeURIComponent(siteType)}/sso`;
        if (memberId) url += `?memberId=${encodeURIComponent(memberId)}`;
        return await this._api.get(url);
    }

    async contentFor(key) {
        try {
            let uiWidget;
            switch (key) {
                case 'home-top': uiWidget = this.site.homeTop; break;
                case 'home-bottom': uiWidget = this.site.homeBottom; break;
                case 'about': uiWidget = this.site.about; break;
                case 'bio': uiWidget = this.site.bio; break;
            }
            if (uiWidget) {
                return uiWidget;
            }
            const uiw = await this._content.uiWidget(key);
            return uiw
        } catch (err) {
            console.log(err);
        }
    }

    async contactForm(memberId, firstName, lastName, email, phone) {
        const model = { firstName, lastName, email, phone };
        await this._api.post(`member/site/${encodeURIComponent(memberId)}/contact-form`, model);
    }

    async create(siteType) {
        return await this._api.post(`member-site/${encodeURIComponent(siteType)}`);
    }

    async createExternal(siteType, memberId) {
        let url = `member-site/${encodeURIComponent(siteType)}/external`;
        if (memberId) url += `?memberId=${encodeURIComponent(memberId)}`;
        return await this._api.post(url);
    }

    async load(siteType) {
        return await this._api.get(`member-site/${encodeURIComponent(siteType)}`);
    }

    async save(siteType, slug, isActive, metadata) {
        await this._api.post(`member-site/${encodeURIComponent(siteType)}/settings`, { slug, isActive, metadata });
    }

    async delete(siteType) {
        await this._api.delete(`member-site/${encodeURIComponent(siteType)}`);
    }

    async deleteFile(memberId, fileId) {
        await this._api.delete(`member-file/${encodeURIComponent(memberId)}/${encodeURIComponent(fileId)}`);
    }

    async renameFile(memberId, fileId, displayName) {
        await this._api.put(`member-file/${encodeURIComponent(memberId)}/${encodeURIComponent(fileId)}/rename?displayName=${encodeURIComponent(displayName)}`);
    }

    async viewFile(file) {
        try {
            const model = { id: file.id };
            await this._page.export(this._api, 'member-file/export-file', model);
        } catch (err) {
            console.log(err);
        }
    }
}
