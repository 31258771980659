import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'common/server';
import { I18n } from 'common/i18n';
import { Config } from 'services/config';
import { ServiceWithListNav } from 'resources/base-classes/service-with-list-nav';
import { formatAddress } from 'services/functions';

export const LEAD_STATUS = {
    New: 'new',
    Calling: 'calling',
    Appointment: 'appointment',
    Application: 'application',
    DoorKnock: 'door-knock',
    Exhausted: 'exhausted',
    Issued: 'issued',

    all: () => {
        return [LEAD_STATUS.New, LEAD_STATUS.Calling, LEAD_STATUS.Appointment, LEAD_STATUS.Application, LEAD_STATUS.DoorKnock, LEAD_STATUS.Exhausted, LEAD_STATUS.Issued ];
    },
    allData: (i18n) => {
        const all = LEAD_STATUS.all();
        const allData = [];
        const defaults = LEAD_STATUS.defaults();
        all.forEach(s => {
            const status = { key: s, name: i18n.tr(`lead-status-${s}`), active: defaults.includes(s), icon: LEAD_STATUS.icon(s) };
            allData.push(status);
        });
        return allData;
    },
    icon: (status) => {
        switch (status) {
            case LEAD_STATUS.New: return 'fa-duotone fa-sparkles';
            case LEAD_STATUS.Calling: return 'fa-duotone fa-phone';
            case LEAD_STATUS.Appointment: return 'fa-duotone fa-calendar-check';
            case LEAD_STATUS.Application: return 'fa-duotone fa-file-contract';
            case LEAD_STATUS.DoorKnock: return 'fa-duotone fa-door-closed';
            case LEAD_STATUS.Exhausted: return 'fa-duotone fa-tombstone-blank';
            case LEAD_STATUS.Issued: return 'fa-duotone fa-file-invoice-dollar';
            default: return 'fa-duotone fa-circle-question';
        }
    },
    defaults: () => {
        return [LEAD_STATUS.New, LEAD_STATUS.Calling, LEAD_STATUS.Appointment, LEAD_STATUS.Application, LEAD_STATUS.DoorKnock];
    },
};

export const LEAD_TYPE = {
    CallIn: 'call-in',
    DirectMail: 'direct-mail',
    InternetProspect: 'internet-prospect',
    InternetRequest: 'internet-request',
    Inventory3090d: 'inv-30-90',
    InventoryU30d: 'inv-u30',
    PolicyOwner: 'policy-owner',
    EmergencyContact: 'emergency-contact',

    all: () => {
        return [LEAD_TYPE.CallIn, LEAD_TYPE.DirectMail, LEAD_TYPE.InternetProspect, LEAD_TYPE.InternetRequest, LEAD_TYPE.Inventory3090d, LEAD_TYPE.InventoryU30d, LEAD_TYPE.PolicyOwner, LEAD_TYPE.EmergencyContact];
    },
};

export const LEAD_AGE = {
    Fresh: 'fresh',
    Mature: 'mature',
    RealTime: 'real-time',

    all: () => {
        return [LEAD_AGE.Fresh, LEAD_AGE.Mature, LEAD_AGE.RealTime];
    }
};

export const LEAD_KPI = {
    Dial: 'dial',
    Connect: 'connect',
    AppointmentBooked: 'appointment-booked',
    AppointmentLeadRatio: 'appointment-lead-ratio',
    ConnectAppointmentRatio: 'connect-appointment-ratio',
    DialAppointmentRatio: 'dial-appointment-ratio',
    DialsPerLead: 'dials-per-lead',
    LeadsReceived: 'lead-received',

    all: () => {
        return [LEAD_KPI.Dial, LEAD_KPI.Connect, LEAD_KPI.AppointmentBooked, LEAD_KPI.AppointmentLeadRatio, LEAD_KPI.ConnectAppointmentRatio, LEAD_KPI.DialAppointmentRatio, LEAD_KPI.DialsPerLead, LEAD_KPI.LeadsReceived];
    },


    default: () => {
        return LEAD_KPI.Dial;
    }
}

export class Leads extends ServiceWithListNav {
    static inject = [EventAggregator, Api, I18n, Config];
    _ea;
    _api;
    _i18n;
    _config;

    constructor(ea, api, i18n, config) {
        super('lpfn.leads.list-nav');
        this._ea = ea;
        this._api = api;
        this._i18n = i18n;
        this._config = config;
    }

    dispositions(status) {
        try {
            const config = JSON.parse(this._config.value('leadsJson'));
            if (!status) return [];
            return config.dispositionsByStatus[status];
        } catch (err) {
            console.log(err);
        }
    }

    allDispositions() {
        try {
            const config = JSON.parse(this._config.value('leadsJson'));
            return config.dispositions;
        } catch (err) {
            console.log(err);
        }
    }

    printTemplateVariables(forHeaderOrFooter = false) {
        try {
            const config = JSON.parse(this._config.value('leadsJson'));
            const variables = [...new Set(config.printTemplates.variables)].sort((a, b) => a.localeCompare(b)); // get the unique variables
            if (!forHeaderOrFooter) return variables;
            return variables.filter(x => x.indexOf('{{lead') < 0 && x.indexOf('{{agent') < 0);
        } catch (err) {
            console.log(err);
            return [];
        }
    }

    async pool(onlyAssigned) {
        let url = 'leads/pool';
        if (onlyAssigned) url += '?onlyAssigned=true';
        const leads = await this._api.get(url);
        return leads;
    }

    async delete(leadIds) {
        return await this._api.delete('leads', leadIds);
    }

    async forAgent(memberId, ids = null, leadTypes = null, notLeadTypes = null) {
        let url = 'leads';
        const payload = { memberId: memberId ?? null, ids, leadTypes, notLeadTypes };
        const leads = await this._api.post(url, payload);
        const unknownCounty = this._i18n.tr('county-unknown');
        leads.forEach(l => {
            if (!l.county) l.county = unknownCounty;
            l.statusName = this._i18n.tr(`lead-status-${l.status}`);
            if (l.type) l.typeName = this._i18n.tr(`lead:type-${l.type}`);
            if (l.disposition) l.dispositionName = this._i18n.tr(`lead:disposition-${l.disposition}`);
            l.formattedAddress = formatAddress(l);
        });
        return leads;
    }

    async byId(leadId) {
        return await this._api.get(`leads/${encodeURIComponent(leadId)}`);
    }

    async updateProfile(leadId, payload) {
        return await this._api.put(`leads/${encodeURIComponent(leadId)}`, payload);
    }

    async assignLeads(memberId, leadIds, includeHistory) {
        return await this._api.post('leads/pool/assign', { memberId, leadIds, includeHistory });
    }

    async assignments(leadId) {
        return await this._api.get(`leads/${encodeURIComponent(leadId)}/assignments`);
    }

    async unassignLeads(leadIds) {
        return await this._api.post('leads/pool/unassign', { leadIds });
    }

    async activities(leadId) {
        return await this._api.get(`leads/${encodeURIComponent(leadId)}/activities`);
    }

    async setStatus(leadId, status) {
        return await this._api.post(`leads/${encodeURIComponent(leadId)}/status/${encodeURIComponent(status)}`);
    }

    async setDisposition(leadId, disposition, notes) {
        const payload = { notes };
        return await this._api.post(`leads/${encodeURIComponent(leadId)}/disposition/${encodeURIComponent(disposition)}`, payload);
    }

    async resetToNew(leadId) {
        return await this._api.post(`leads/${encodeURIComponent(leadId)}/reset-new`);
    }

    async filters() {
        return await this._api.get('leads/auto-assign/filter');
    }

    async saveFilter(id, name) {
        return await this._api.post('leads/auto-assign/filter', { id, name });
    }

    async deleteFilter(id) {
        return await this._api.delete(`leads/auto-assign/filter/${encodeURIComponent(id)}`);
    }

    async setFilters(id, filters) {
        return await this._api.post(`leads/auto-assign/filter/${encodeURIComponent(id)}`, filters);
    }

    async assignAgentToFilter(id, agentId, percentage) {
        return await this._api.post(`leads/auto-assign/filter/${encodeURIComponent(id)}/assignment`, { agentId, percentage });
    }

    async removeAgentFromFilter(id, agentId) {
        return await this._api.delete(`leads/auto-assign/filter/${encodeURIComponent(id)}/assignment/${encodeURIComponent(agentId)}`);
    }

    async setAutoAssignOrdering(orderedIds) {
        return await this._api.post('leads/auto-assign/filter-ordering', orderedIds);
    }

    async doNotCall(leadId) {
        return await this._api.post(`leads/${encodeURIComponent(leadId)}/do-not-call`);
    }

    async removeDoNotCall(leadId) {
        return await this._api.post(`leads/${encodeURIComponent(leadId)}/remove-do-not-call`);
    }

    async canAccess(leadId) {
        const data = await this._api.get(`leads/${encodeURIComponent(leadId)}/can-access`);
        return data.canAccess;
    }

    async opportunities(usCountyFips) {
        let url = 'leads/opportunities';
        if (usCountyFips) {
            if (Array.isArray(usCountyFips)) url += `?county=${encodeURIComponent(usCountyFips.join(','))}`;
            else url += `?county=${encodeURIComponent(usCountyFips)}`;
        }
        return await this._api.get(url);
    }

    async currentInventoryOpportunities(usCountyFips) {
        let url = 'leads/opportunities/inventory';
        if (usCountyFips) {
            if (Array.isArray(usCountyFips)) url += `?county=${encodeURIComponent(usCountyFips.join(','))}`;
            else url += `?county=${encodeURIComponent(usCountyFips)}`;
        }
        return await this._api.get(url);
    }

    async kpis(timePeriod, agentId, start, end, kpi, view, groupBy) {
        let url = 'leads/kpis';
        const qs = [];
        if (timePeriod) qs.push(`timePeriod=${encodeURIComponent(timePeriod)}`);
        if (agentId) qs.push(`memberId=${encodeURIComponent(agentId)}`);
        if (start) qs.push(`start=${encodeURIComponent(start)}`);
        if (end) qs.push(`end=${encodeURIComponent(end)}`);
        if (kpi) qs.push(`kpi=${encodeURIComponent(kpi)}`);
        if (view) qs.push(`view=${encodeURIComponent(view)}`);
        if (groupBy) qs.push(`groupBy=${encodeURIComponent(groupBy)}`);
        if (qs.length) url += `?${qs.join('&')}`;
        return this._api.get(url);
    }
}
