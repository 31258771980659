import {inject} from 'aurelia-framework';
import {Security} from 'common/security';
import {Api} from 'common/server';
import moment from 'moment';

@inject(Security, Api)
export class MemberService {
    _security;
    _api;

    constructor(security, api) {
        this._security = security;
        this._api = api;
    }

    getProfile(memberId) {
        memberId = memberId || this._security.authenticatedMemberId;
        return new Promise((resolve, reject) => {
            this._api.getData('member/' + memberId).then(data => {
                resolve(data);
            });
        });
    }

    getMyDownline() {
        return this.getDownline(this._security.authenticatedMemberId);
    }

    getDownline(memberId) {
        memberId = memberId || this._security.authenticatedMemberId;
        return new Promise((resolve, reject) => {
            this._api.getData('member/' + memberId + '/downline').then(data => {
                resolve(data);
            });
        });
    }
}