import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'common/server';
import { Security } from 'common/security';
import { c } from 'common/common';
import environment from '../../config/environment.json';

export const MESSAGE_STATUS = {
    current: 'current',
    archived: 'archived',
    all: 'all',
    notifications: 'notifications',
};

export class Messages {
    static inject = [EventAggregator, Api, Security];
    _ea;
    _api;
    _security;

    counts = {};
    _checker;
    _checkEveryMinutes = environment.messageCheckMinutes;
    _errorCtr = 0;

    _handlers = [];

    NAV_KEY = 'lpfn.messages-nav';
    _localStorage;

    constructor(ea, api, security) {
        this._ea = ea;
        this._api = api;
        this._security = security;
        this._localStorage = window.localStorage;
    }

    async initialize() {
        this._startLoadCounts(true, true);
    }

    dispose() {
        this._clearChecker();
        this.clearNav();
        this.counts = {};
    }

    async reload(showNotification = true) {
        this._startLoadCounts(showNotification);
    }

    _startLoadCounts(showNotification = true, runChecker = false) {
        window.setTimeout(() => this._loadCounts(showNotification, runChecker), 500);
    }

    async _loadCounts(showNotification = true, runChecker = false) {
        if (!this._security.isAuthenticated) {
            this.dispose();
            return;
        }
        try {
            this.counts = await this._api.get('message-counts');
            this._fireEvent(showNotification);
            this._errorCtr = 0;
        } catch (err) {
            this.errorCtr++;
        } finally {
            if (this._errorCtr > 10) return;
            if (runChecker) {
                this._checker = window.setTimeout(() => this._loadCounts(true, true), this._checkEveryMinutes * 60 * 1000);
            }
        }
    }

    async current() {
        try {
            return await this._api.get('message');
        } catch (err) {
            console.log(err);
        }
    }

    async list(status) {
        try {
            const messages = await this._api.get(`message?messageStatus=${encodeURIComponent(status)}`);
            return messages;
        } catch (err) {
            console.log(err);
        }
    }

    async listFor(type, forId) {
        return await this._api.get(`message/${encodeURIComponent(type)}/${encodeURIComponent(forId)}`);
    }

    _fireEvent(showNotification = true) {
        this._ea.publish(c.EventKeys.messages.updated, { showNotification });
    }

    _clearChecker() {
        if (!this._checker) return;
        window.clearTimeout(this._checker);
        this._checker = undefined;
    }

    async get(id) {
        return await this._api.get(`message/${encodeURIComponent(id)}`);
    }

    async archive(messageId, isArchived) {
        const response = await this._api.post(`message/${encodeURIComponent(messageId)}/archive/${encodeURIComponent(isArchived)}`);
        this._startLoadCounts(false);
        return response;
    }

    async read(messageId, isRead) {
        const response = await this._api.post(`message/${encodeURIComponent(messageId)}/read/${encodeURIComponent(isRead)}`);
        this._startLoadCounts();
        return response;
    }

    async massArchive(messageIds, isArchived) {
        const response = await this._api.post(`message/archive/${encodeURIComponent(isArchived)}`, messageIds);
        this._startLoadCounts(false);
        return response;
    }

    async massRead(messageIds, isRead) {
        const response = await this._api.post(`message/read/${encodeURIComponent(isRead)}`, messageIds);
        this._startLoadCounts();
        return response;
    }

    async create(messageType, subject, content, toMemberId, replyToMessageId, start, end, filterBy, level, levelOrAbove, memberIdAndDownline) {
        return this.save(replyToMessageId || null, subject, content, toMemberId, messageType, start, end, filterBy, level, levelOrAbove, null, memberIdAndDownline);
    }

    async edit(id, messageType, subject, content, start, end, filterBy, level, levelOrAbove, memberIdAndDownline) {
        return this.save(null, subject, content, null, messageType, start, end, filterBy, level, levelOrAbove, id, memberIdAndDownline);
    }

    async reply(toMessageId, subject, content, toMemberId) {
        return this.save(toMessageId, subject, content, toMemberId);
    }

    async save(replyToMessageId, subject, content, toMemberId, messageType, startDate, endDate, filterBy, level, levelOrAbove, editMessageId, memberIdAndDownline) {
        let toFilter = null;
        if (filterBy === 'Life' || filterBy === 'Annuity' || filterBy === 'HMA') {
            toFilter = {
                levelType: filterBy,
                level: level,
                levelOrAbove: levelOrAbove ? true : false
            }
        }
        if (filterBy === 'agent-and-downline') {
            toFilter = {
                memberIdAndDownline: memberIdAndDownline,
            }
        }
        const model = {
            id: editMessageId || null,
            replyToMessageId,
            subject,
            content,
            toMemberId,
            messageType,
            startDate,
            endDate,
            toFilter
        };
        const response = await this._api.post('message', model);
        this._startLoadCounts(false);
        return response;
    }

    async delete(messageId) {
        const response = await this._api.delete(`message/${encodeURIComponent(messageId)}`);
        this._startLoadCounts(false);
        return response;
    }

    clearNav() {
        this._localStorage.removeItem(this.NAV_KEY);
    }

    setNavList(messages) {
        try {
            const ids = messages.map(x => x.id);
            this._localStorage.setItem(this.NAV_KEY, JSON.stringify(ids));
        } catch (err) {
            console.log(err);
        }
    }

    getNav(currentId) {
        try {
            const ids = JSON.parse(this._localStorage.getItem(this.NAV_KEY) || '[]');
            const currentIndex = ids.indexOf(currentId);
            if (currentIndex < 0) {
                this.clearNav();
                return { hasNav: false };
            }
            const previousId = currentIndex > 0 ? ids[currentIndex - 1] : undefined;
            const nextId = currentIndex >= 0 && currentIndex < ids.length ? ids[currentIndex + 1] : undefined;
            return {
                hasNav: previousId || nextId ? true : false,
                previousId,
                nextId
            };
        } catch (err) {
            console.log(err);
        }
    }
}
