import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'common/server';
import { Security } from 'common/security';
import { Page } from 'common/ui';
import { AgentNumbers } from 'services/agent-numbers';
import { DIALER_TEAM } from 'services/dialer';
import { c } from 'common/common';

export class Members {
    static inject = [EventAggregator, Api, Security, Page, AgentNumbers];
    _ea;
    _api;
    _security;
    _page;
    _agentNumbers;

    constructor(ea, api, security, page, agentNumbers) {
        this._ea = ea;
        this._api = api;
        this._security = security;
        this._page = page;
        this._agentNumbers = agentNumbers;
    }

    async access(id) {
        return this._api.get(`member/${encodeURIComponent(id)}/access`);
    }

    async getAgentNumber(memberId, carrierId) {
        return await this._api.get(`member/${encodeURIComponent(memberId)}/carrier-number?carrierId=${encodeURIComponent(carrierId)}`);
    }

    async saveAgentNumber(memberId, id, carrierId, agentNumber, lineOfBusiness, visible, status, comments, assignedDate) {
        if (status === 'null') status = null;
        const model = {
            id,
            carrierId,
            agentNumber,
            lineOfBusiness,
            visible,
            status,
            comments,
            assignedDate,
        };
        return await this._api.post(`member/${encodeURIComponent(memberId)}/carrier-number`, model);
    }

    async deleteAgentNumber(memberId, id) {
        return await this._api.delete(`member/${encodeURIComponent(memberId)}/carrier-number/${encodeURIComponent(id)}`);
    }

    async downloadVCard(id) {
        await this._page.exportData(this._api, `member/${encodeURIComponent(id)}/export-vcard-key`, 'member/export-vcard');
    }

    async setTeam(id, isTeam) {
        await this._api.post(`member/${encodeURIComponent(id)}/${isTeam ? 'make-team' : 'remove-team'}`);
    }

    async setAgency(id, isAgency) {
        await this._api.post(`member/${encodeURIComponent(id)}/${isAgency ? 'make-agency' : 'remove-agency'}`);
    }

    async isSuppressed(id) {
        const data = await this._api.get(`member/${encodeURIComponent(id)}/is-suppressed`);
        return data.isSuppressed;
    }

    async setSuppression(id, suppress) {
        await this._api.post(`member/${encodeURIComponent(id)}/${suppress ? 'suppress' : 'unsuppress'}`);
    }

    async levelChangeOptions(id) {
        return await this._api.get(`member/${encodeURIComponent(id)}/level-change-options`);
    }

    async requestLevelChange(id, levelType, level, comments) {
        await this._api.post(`member/${encodeURIComponent(id)}/request-level-change`, { levelType: levelType, requestedLevel: level, requestComments: comments });
    }

    async getMember(memberId) {
        return await this._api.get(`member/${encodeURIComponent(memberId || this._security.authenticatedMemberId)}/public`)
    }

    async getDirectUpline(memberId) {
        return await this._api.get(`member/${encodeURIComponent(memberId || this._security.authenticatedMemberId)}/direct-upline`)
    }

    async getMentor(memberId) {
        return await this._api.get(`member/${encodeURIComponent(memberId || this._security.authenticatedMemberId)}/mentor`)
    }

    async getAgency(memberId) {
        return await this._api.get(`member/${encodeURIComponent(memberId || this._security.authenticatedMemberId)}/agency`)
    }

    async masterAgency(memberId) {
        return await this._api.get(`member/${encodeURIComponent(memberId || this._security.authenticatedMemberId)}/master-agency`);
    }

    async getMassCommunicationStatus(memberId) {
        return await this._api.get(`member/${encodeURIComponent(memberId)}/mass-communication-status`);
    }

    async settings(memberId, type) {
        return await this._api.get(`member/${encodeURIComponent(memberId)}/setting/${encodeURIComponent(type)}`);
    }

    async settingValue(memberId, key) {
        const setting = await this._api.get(`member/${encodeURIComponent(memberId)}/setting/${encodeURIComponent(key)}/value`);
        if (!setting) return undefined;
        return setting.value;
    }

    async saveSettings(memberId, type, model) {
        const response = await this._api.post(`member/${encodeURIComponent(memberId)}/setting/${encodeURIComponent(type)}`, model);
        this._ea.publish(c.EventKeys.agent.configUpdated);
        return response;
    }

    async canAccessProfile(memberId) {
        const response = await this._api.get(`member/${encodeURIComponent(memberId)}/can-access-profile`);
        return response.canAccessProfile;
    }

    async licenses(memberId) {
        return await this._api.get(`member/${encodeURIComponent(memberId)}/licenses`);
    }

    async downlineContractedInState(memberId, usState, carrierId) {
        const params = [];
        if (usState) params.push(`state=${encodeURIComponent(usState)}`);
        if (carrierId) params.push(`carrierId=${encodeURIComponent(carrierId)}`);
        const qs = params.length === 0 ? '' : `?${params.join('&')}`;
        const downline = await this._api.get(`member/${memberId}/downline/contracted${qs}`);
        for (let d of downline) {
            for (let an of d.carrierNumbers) {
                an.statusColor = await this._agentNumbers.statusColor(an);
            }
        }
        return downline;
    }

    async isInDownline(memberId) {
        const data = await this._api.get(`member/${encodeURIComponent(memberId)}/is-in-downline`);
        return data.isInDownline;
    }

    async autoDialTeam() {
        return await this.settingValue(this._security.authenticatedMemberId, DIALER_TEAM.Single);
    }
}
