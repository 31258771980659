import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'common/server';
import { Config } from 'services/config';
import { fileIsImage, fileIcon } from './files';
import environment from '../../config/environment.json';
import { c } from 'common/common';

export class FilesFor {
    static inject = [EventAggregator, Api, Config];
    _ea;
    _api;
    _config;

    settings = {};

    constructor(ea, api, config) {
        this._ea = ea;
        this._api = api;
        this._config = config;
        this._initialize();
    }

    _initialize() {
        try {
            this.settings = JSON.parse(this._config.value('filesForJson'));
        } catch (err) {
            console.log(err);
        }
    }

    async list(forType, forId, fileType = null) {
        let url = `file-for/${encodeURIComponent(forType)}/${encodeURIComponent(forId)}`;
        if (fileType) url += `?fileType=${encodeURIComponent(fileType)}`;
        const files = await this._api.get(url);
        files.forEach(f => {
            f.isImage = fileIsImage(f.mediaType);
            f.url = `${environment.api}/api/file-for/${f.id}`;
            f.icon = fileIcon(f.mediaType);
        });
        return files;
    }

    viewSrc(fileId) {
        return `${environment.api}/api/file-for/${encodeURIComponent(fileId)}?dispositionType=inline`;
    }

    async delete(fileId, forId) {
        const response = await this._api.delete(`file-for/${encodeURIComponent(fileId)}`);
        this._ea.publish(c.EventKeys.fileFor.updated(forId));
        return response;
    }

    async rename(fileId, displayName) {
        await this._api.put(`file-for/${encodeURIComponent(fileId)}/rename?displayName=${encodeURIComponent(displayName)}`);
    }
}
