import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'common/server';
import { I18n } from 'common/i18n';
import { c } from 'common/common';

export class Analytics {
    static inject = [EventAggregator, Api, I18n];
    _ea;
    _api;
    _i18n;

    constructor(ea, api, i18n) {
        this._ea = ea;
        this._api = api;
        this._i18n = i18n;
    }

    async forPolicies(timePeriod, asOfMoment, startDate, endDate) {
        let url = 'analytics/policies';
        const qs = [];
        if (timePeriod) qs.push(`timePeriod=${encodeURIComponent(timePeriod)}`);
        if (asOfMoment) qs.push(`asOfDate=${encodeURIComponent(asOfMoment.format())}`);
        if (startDate) qs.push(`startDate=${encodeURIComponent(startDate)}`);
        if (endDate) qs.push(`endDate=${encodeURIComponent(endDate)}`);
        if (qs.length) url += `?${qs.join('&')}`;
        const policies = await this._api.get(url);
        policies.forEach(p => {
            p.policyNumberCallback = () => this._openPolicyCard(p.policy);
            if (p.writingAgentId) p.writingAgentCallback = () => this._openAgentCard(p.writingAgentId);
            if (p.splitWritingAgentId) p.splitWritingAgentCallback = () => this._openAgentCard(p.splitWritingAgentId);
            if (p.teamId) p.teamCallback = () => this._openAgentCard(p.teamId);
            if (p.agencyId) p.agencyCallback = () => this._openAgentCard(p.agencyId);
            if (p.masterAgencyId) p.masterAgencyCallback = () => this._openAgentCard(p.masterAgencyId);
        });
        return policies;
    }

    async forAgents() {
        const agents = await this._api.get('analytics/agents');
        agents.forEach(a => {
            if (a.lifeLevel) a.lifeLevelName = this._i18n.tr(`level-${a.lifeLevel}`);
            if (a.annuityLevel) a.annuityLevelName = this._i18n.tr(`level-${a.annuityLevel}`);
            if (a.hmaLevel) a.hmaLevelName = this._i18n.tr(`level-${a.hmaLevel}`);
        });
        return agents;
    }

    _openPolicyCard(policy) {
        this._ea.publish(c.EventKeys.site.openPolicy, { policyId: policy.id });
    }

    _openAgentCard(agentId) {
        this._ea.publish(c.EventKeys.site.openProfile, { memberId: agentId });
    }
}
