import { c } from 'common/common';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { SplashScreen } from '@capacitor/splash-screen';
import { Keyboard } from '@capacitor/keyboard';
import { App } from '@capacitor/app';
import { ScreenOrientation } from '@capacitor/screen-orientation';

/**
 * @returns {Promise<String>} platform - android, ios, web
 */
 export async function getDevicePlatform() {
    const info = await Device.getInfo();
    return info ? info.platform : 'web';
}

/**
 * Hide the splash screen if native
 */
export function hideSplashScreen() {
    if (!Capacitor.isNativePlatform()) return;
    SplashScreen.hide();
}

/**
 * Hide the keyboard if native
 */
export async function hideKeyboard() {
    if (!Capacitor.isNativePlatform()) return;
    await Keyboard.hide();
}

/**
* Opens a uri in the system
* @param {String} uri - the uri to load
* @returns {Promise<void>}
*/
export async function openUrl(uri) {
    if (!Capacitor.isNativePlatform()) {
        await App.openUrl({ url: uri });
    } else {
        window.open(uri, '_system');
    }
}

/**
* Opens a uri in a new browser within the app
* @param {String} uri - the uri to load
* @returns {Promise<void>}
*/
export async function openBrowser(uri) {
    await Browser.open({ url: uri });
}

/**
 * @param {Function} callback
 * @returns Listener
 */
export function addAppStateChangeListener(callback) {
    return App.addListener('appStateChange', (state) => callback(state));
}

export async function initializeKeyboardEvents(ea) {
    if (!Capacitor.isNativePlatform()) return;
    const platform = await getDevicePlatform();
    Keyboard.addListener('keyboardDidShow', (info) => {
        try {
            if (!Capacitor.isNativePlatform()) return;
            ea.publish(c.EventKeys.keyboard.opened, { height: info.keyboardHeight });
        } catch (err) {
            // do nothing
        }
    });

    Keyboard.addListener('keyboardWillHide', () => {
        try {
            if (!Capacitor.isNativePlatform()) return;
        } catch (err) {
            // do nothing
        }
    });

    Keyboard.addListener('keyboardDidHide', () => {
        try {
            if (!Capacitor.isNativePlatform()) return;
            ea.publish(c.EventKeys.keyboard.closed);
        } catch (err) {
            // do nothing
        }
    });
}

export async function screenOrientation() {
    try {
        const data = await ScreenOrientation.orientation();
        const orientation = data.type.indexOf('portrait') >= 0 ? 'portrait' : 'landscape';
        return orientation;
    } catch (err) {
        console.log(err);
    }
}

export function addScreenOrientationListener(callback) {
    try {
        ScreenOrientation.addListener('screenOrientationChange', (data) => {
            const orientation = data.type.indexOf('portrait') >= 0 ? 'portrait' : 'landscape';
            callback(orientation);
        });
    } catch (err) {
        console.log(err);
    }
}