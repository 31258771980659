import { Api } from 'common/server';

export class Favorites {
    static inject = [Api];
    _api;

    _favoritesByType = {};

    constructor(api) {
        this._api = api;
    }

    async ids(type) {
        if (this._favoritesByType[type]) return this._favoritesByType[type];
        const ids = await this._api.get(`favorite/${encodeURIComponent(type)}`);
        this._favoritesByType[type] = ids;
        return ids;
    }

    async set(type, id, isFavorite) {
        const url = `favorite/${encodeURIComponent(type)}/${encodeURIComponent(id)}?isFavorite=${isFavorite ? 'true' : 'false'}`;
        const ids = await await this._api.post(url);
        this._favoritesByType[type] = ids;
        return ids;
    }
}
