import { Api } from 'common/server';

export class Config {
    static inject = [Api];
    _api;

    all;

    constructor(api) {
        this._api = api;
    }

    async initialize() {
        try {
            this.all = await this._api.get('config');
        } catch (err) {
            console.log(err);
        }
    }

    value(key) {
        if (!this.all) return undefined;
        return this.all[key];
    }

    json(key) {
        const value = this.value(key);
        if (!value) return value;
        return JSON.parse(value);
    }

    async get(key) {
        return await this._api.get(`config/${encodeURIComponent(key)}`);
    }
}
