import * as phoneNumberLib from 'libphonenumber-js';

export function formatPhone(value) {
    try {
        const phoneNumber = phoneNumberLib.parsePhoneNumber(value, 'US');
        if (phoneNumber.isValid()) return phoneNumber.formatNational();
        return value;
    } catch (err) {
        return value;
    }
}

export function formatAddress(object) {
    try {
        let address = '';
        if (!object) return address;
        if (object.streetAddress) address += `${object.streetAddress}<br>`;
        if (object.streetAddress2) address += `${object.streetAddress2}<br>`;
        if (object.address) address += `${object.address}<br>`;
        if (object.address2) address += `${object.address2}<br>`;
        if (object.city) address += `${object.city} `;
        if (object.state) address += object.state;
        if (object.state && (object.zip || object.zipCode)) address += ', ';
        if (object.zip) address += object.zip;
        if (object.zipCode) address += object.zipCode;
        if (object.county) address += `<br>${object.county}`;
        return address;
    } catch (err) {
        return value;
    }
}
