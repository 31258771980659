import {Api} from 'common/server';

export class Leaderboards {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async all() {
        return await this._api.get('leaderboard');
    }

    async byCode(code, lineOfBusiness, limit, forDate) {
        let url = `leaderboard/by-code/${encodeURIComponent(code)}/${encodeURIComponent(lineOfBusiness)}`;
        const qs = [];
        if (limit) qs.push(`limit=${encodeURIComponent(limit)}`);
        if (forDate) qs.push(`date=${encodeURIComponent(forDate.toISOString(true))}`);
        if (qs.length) url += `?${qs.join('&')}`;
        const leaderboard = await this._api.get(url);
        leaderboard.leaders.forEach(l => {
            l.member = { id: l.memberId, firstName: l.firstName, lastName: l.lastName, fullName: `${l.firstName} ${l.lastName}` };
        });
        return leaderboard;
    }

    async entries(code, memberId) {
        return await this._api.get(`leaderboard/by-code/${encodeURIComponent(code)}/entries/${encodeURIComponent(memberId)}`);
    }

    async updateEntry(id, entryDate, value, lineOfBusiness) {
        const model = { entryDate, value, lineOfBusiness };
        return await this._api.put(`leaderboard/entry/${encodeURIComponent(id)}`, model);
    }

    async deleteEntry(id) {
        return await this._api.delete(`leaderboard/entry/${encodeURIComponent(id)}`);
    }
}
