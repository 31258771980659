import { Api } from 'common/server';

export class Levels {
    static inject = [Api];
    _api;

    _allByType;

    constructor(api) {
        this._api = api;
    }

    async byType() {
        if (this._allByType) return this._allByType;
        this._allByType = await this._api.get(`level/all-by-type`);
        return this._allByType;
    }

    async byTypeRestricted(restrictedByMemberId) {
        return await this._api.get(`level/all-by-type?restrictedByMemberId=${encodeURIComponent(restrictedByMemberId)}`);
    }
}
