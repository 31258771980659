import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'common/server';
import { c } from 'common/common';

export class MemberConfig {
    static inject = [EventAggregator, Api];
    _api;

    all;
    _handlers = [];

    constructor(ea, api) {
        this._ea = ea;
        this._api = api;
    }

    async initialize() {
        try {
            await this._load();
            this._handlers.push(this._ea.subscribe(c.EventKeys.agent.configUpdated, () => this._load()));
        } catch (err) {
            console.log(err);
        }
    }

    async _load() {
        try {
            this.all = await this._api.get('member-config');
        } catch (err) {
            console.log(err);
        }
    }

    dispose() {
        this.all = undefined;
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    value(key) {
        if (!this.all) return undefined;
        const setting = this.all.find(x => x.key === key);
        if (!setting) return null;
        return setting.value;
    }

    json(key) {
        const value = this.value(key);
        if (!value) return value;
        return JSON.parse(value);
    }

    async save(key, value) {
        this.all = await this._api.post(`member-config/${encodeURIComponent(key)}`, { settingValue: value});
    }

    async reset() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
        await this.initialize();
    }

    async jsonFor(memberId, key) {
        const all = await this._api.get(`member-config/${encodeURIComponent(memberId)}`);
        const setting = all.find(x => x.key === key);
        if (!setting) return null;
        if (!setting.value) return null;
        return JSON.parse(setting.value);
    }
}
