import { Api } from 'common/server';
import { I18n } from 'common/i18n';

export class LeadSources {
    static inject = [Api, I18n];
    _api;
    _i18n;

    constructor(api, i18n) {
        this._api = api;
        this._i18n = i18n;
    }

    async all() {
        const ls = await this._api.get('lead-sources');
        ls.forEach(x => {
            x.receivedAgeValue = this._i18n.tr(`lead:age-${x.receivedAge}`);
            x.leadTypeValue = this._i18n.tr(`lead:type-${x.leadType}`);
        });
        return ls;
    }

    async save(payload) {
        return await this._api.post('lead-sources', payload);
    }

    async delete(id) {
        return await this._api.delete(`lead-sources/${encodeURIComponent(id)}`);
    }
}
