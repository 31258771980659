import environment from '../../config/environment.json';
import { Security } from 'common/security';
import { Api } from 'common/server';
import { MemberConfig } from 'services/member-config';
import { Config } from 'services/config';

export class Nylas {
    static inject = [Security, Api, MemberConfig, Config];
    _security;
    _api;
    _memberConfig;
    _config;

    _nylasIntegration;
    schedulePageDomain;
    schedulePageBase;
    _nylasLoaded = false;
    _forMemberId;
    _forMemberNylasConfig;

    constructor(security, api, memberConfig, config) {
        this._security = security;
        this._api = api;
        this._memberConfig = memberConfig;
        this._config = config;
        this.schedulePageDomain = this._config.value('yourCalendarOnlineUrl');
        this.schedulePageBase = `${this.schedulePageDomain || 'https://schedule.yourcalendaronline.com'}#`;
    }

    loadScript() {
        try {
            const existingScript = document.getElementById('lpfn-nylas-script');
            if (existingScript) return;
            const scriptEl = document.createElement('script');
            scriptEl.setAttribute('id', 'lpfn-nylas-script');
            scriptEl.setAttribute('type', 'text/javascript');
            scriptEl.setAttribute('src', 'https://schedule.nylas.com/schedule-editor/v1.0/schedule-editor.js');
            document.body.appendChild(scriptEl);
        } catch (err) {
            console.log(err);
        }
    }

    async _nylasConfig(forMemberId) {
        if (forMemberId && !this._security.isAuthenticatedMember(forMemberId)) {
            // Need to load the nylas config for forMemberId
            if (this._forMemberId === forMemberId && this._forMemberNylasConfig) return this._forMemberNylasConfig;
            this._forMemberId = forMemberId;
            this._forMemberNylasConfig = await this._memberConfig.jsonFor(this._forMemberId, 'nylas');
            console.log(this._forMemberNylasConfig);
            return this._forMemberNylasConfig;
        }
        return this._memberConfig.json('nylas');
    }

    async mustReauth(forMemberId) {
        try {
            const nylas = await this._nylasConfig(forMemberId);
            if (!nylas) return false;
            return nylas.mustReauth;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    async authorizedEmail(forMemberId) {
        try {
            const nylas = await this._nylasConfig(forMemberId);
            if (!nylas) return undefined;
            return nylas.email;
        } catch (err) {
            console.log(err);
            return undefined;
        }
    }

    async email(overrideEmail, forMemberId) {
        try {
            const nylas = await this._nylasConfig(forMemberId);
            if (!nylas) {
                if (overrideEmail) return overrideEmail;
                return this._security.agent.email;
            }
            if (!nylas.email) return overrideEmail;
            return nylas.email;
        } catch (err) {
            console.log(err);
            if (this._security.agent) return this._security.agent.email;
            return '';
        }
    }

    async grantId(forMemberId) {
        try {
            const nylas = await this._nylasConfig(forMemberId);
            if (!nylas) return null;
            return nylas.grantId;
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    async isAuthenticated(forMemberId) {
        return await this.grantId(forMemberId) ? true : false;
    }

    async _loadSettings() {
        if (this._nylasIntegration) return;
        const nylasIntegration = await this._api.get('config/NylasIntegration');
        if (nylasIntegration == null) return;
        this._nylasIntegration = JSON.parse(nylasIntegration);
    }

    async clientId() {
        try {
            await this._loadSettings();
            return this._nylasIntegration.clientId;
        } catch (err) {
            console.log(err);
        }
    }

    async redirectUri() {
        try {
            await this._loadSettings();
            let redirectUri = `${environment.url}${this._nylasIntegration.authenticationRedirectUri}`;
            return redirectUri;
        } catch (err) {
            console.log(err);
        }
    }

    async redirectToAuthUrl(email, from, forMemberId) {
        try {
            const stateValue = `${forMemberId ?? this._security.authenticatedMemberId}|${email}`;

            await this._loadSettings();
            let redirectUri = `${environment.url}${this._nylasIntegration.authenticationRedirectUri}`;
            if (window.location.origin && window.location.origin.indexOf('imobackoffice.com') >= 0) {
                redirectUri = `${window.location.origin}${this._nylasIntegration.authenticationRedirectUri}`;
            }
            if (from) redirectUri += `/${from}`;
            console.log(this._nylasIntegration);
            return `https://api.us.nylas.com/v3/connect/auth?client_id=${this._nylasIntegration.clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&access_type=online&prompt=select_provider&state=${encodeURIComponent(stateValue)}&login_hint=${encodeURIComponent(email)}`;
        } catch (err) {
            console.log(err);
        }
    }

    schedulerUrl(slug) {
        return `${this.schedulePageBase}${slug}`;
    }

    schedulerSlug(url) {
        let slug = url.substr(this.schedulePageBase.length);
        if (slug.indexOf('/') === 0) slug = slug.substr(1);
        return slug;
    }

    async editScheduler(forMemberId) {
        try {
            const grantId = await this.grantId(forMemberId);
            if (!grantId) return;
            nylas.scheduler.show({
                pageDomain: this.schedulePageBase,
                auth: { grantId },
                style: { tintColor: '#32325d', backgroundColor: 'white' },
                defaults: {
                    appearance: { show_autoschedule: false, show_nylas_branding: false },
                    event: {
                        duration: 10,
                    },
                },
            });
        } catch (err) {
            console.log(err);
        }
    }

    async addressBook() {
        return await this._api.get('nylas/address-book');
    }

    async authenticated(code, stateValue) {
        const statePipeIdx = stateValue.indexOf('|');
        const memberId = stateValue.substr(0, statePipeIdx);
        const email = stateValue.substr(statePipeIdx + 1);
        const payload = { code, memberId, email };
        console.log(stateValue, email, memberId);
        await this._api.post('nylas/authenticated', payload);
        await this._memberConfig.initialize();
    }

    async resetAuth() {
        const response = await this._api.post('nylas/reset-auth');
        await this._memberConfig.reset();
        return response;
    }
}