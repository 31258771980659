import { Api } from 'common/server';

export class AgentNumbers {
    static inject = [Api];
    _api;

    _contractStatuses;

    constructor(api) {
        this._api = api;
    }

    dispose() {
        this._contractStatuses = undefined;
    }

    async forCarrier(carrierId) {
        return await this._api.get(`member/carrier-number/me/${encodeURIComponent(carrierId)}`);
    }

    async statusColor(agentNumber) {
        try {
            if (agentNumber.agentNumber && !agentNumber.status) return 'green';
            await this._loadContractStatuses();
            const contractStatusIndex = this._contractStatuses.findIndex(x => x.code === agentNumber.status);
            if (contractStatusIndex < 0) return null;
            return this._contractStatuses[contractStatusIndex].color;
        } catch (err) {
            console.log(err);
            this._contractStatuses = undefined;
        }
    }

    async agentNumberStatuses() {
        try {
            await this._loadContractStatuses();
            return this._contractStatuses;
        } catch (err) {
            console.log(err);
            this._contractStatuses = undefined;
        }
    }

    async _loadContractStatuses() {
        if (this._contractStatuses) return;
        try {
            const statuses = await this._api.get('config/ContractStatuses');
            if (statuses) this._contractStatuses = JSON.parse(statuses);
            else this._contractStatuses = [];
        } catch (err) {
            console.log(err);
        }
    }
}
