import { Api } from 'common/server';

export class Commissions {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async all(memberId, widgetPeriod, asOfDate, groupBy, policyId, clientId, carrierId) {
        let url = 'commission';
        const qs = [];
        if (memberId) qs.push(`memberId=${encodeURIComponent(memberId)}`);
        if (widgetPeriod) qs.push(`widgetPeriod=${encodeURIComponent(widgetPeriod)}`);
        if (asOfDate) qs.push(`asOfDate=${encodeURIComponent(asOfDate)}`);
        if (groupBy) qs.push(`groupBy=${encodeURIComponent(groupBy)}`);
        if (policyId) qs.push(`policyId=${encodeURIComponent(policyId)}`);
        if (clientId) qs.push(`clientId=${encodeURIComponent(clientId)}`);
        if (carrierId) qs.push(`carrieId=${encodeURIComponent(carrierId)}`);
        if (qs.length) url += `?${qs.join('&')}`;
        return await this._api.get(url);
    }
}
