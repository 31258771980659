import { Api } from 'common/server';

export class Notes {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async list(type, aboutId) {
        let url = `note/${encodeURIComponent(type)}/${encodeURIComponent(aboutId)}`;
        return await this._api.get(url);
    }

    async save(id, noteType, aboutId, notes) {
        const model = { id, noteType, aboutId, notes };
        return await this._api.post('note', model);
    }

    async delete(id) {
        return await this._api.delete(`note/${encodeURIComponent(id)}`);
    }
}
