import { Api } from 'common/server';

export class MemberAssistantLogins {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async forMember(memberId) {
        const url = memberId ? `member-assistant?memberId=${encodeURIComponent(memberId)}` : 'member-assistant';
        return await this._api.get(url);
    }

    async save(id, email, password, firstName, lastName) {
        const model = { id, email, password, firstName, lastName };
        return await this._api.post('member-assistant', model);
    }

    async changePassword(id, password) {
        const model = { id, password };
        return await this._api.post(`member-assistant/${encodeURIComponent(id)}/set-password`, model);
    }

    async delete(id) {
        const url = `member-assistant/${encodeURIComponent(id)}`;
        return await this._api.delete(url);
    }
}
