import { Api } from 'common/server';
import { Security } from 'common/security';

export const DIALER_TEAM = {
    Single: 'dialer-team-single',
    NewLead: 'dialer-team-new-lead',
    Double: 'dialer-team-double',
    Triple: 'dialer-team-triple',
    NoPrompt: 'dialer-team-no-prompt',

    all: () => {
        return [DIALER_TEAM.Single, DIALER_TEAM.NewLead, DIALER_TEAM.Double, DIALER_TEAM.Triple, DIALER_TEAM.NoPrompt];
    },
}

export class Dialer {
    static inject = [Api, Security];
    _api;
    _security;

    constructor(api, security) {
        this._api = api;
        this._security = security;
    }

    async teams() {
        return await this._api.get('dialer/teams');
    }

    async agentTeams(agentId) {
        const teamSettings = await this._api.get(`dialer/agent/${encodeURIComponent(agentId)}/teams/`);
        return teamSettings.map(x => x.dialerTeamSettingKey);
    }

    async canDial(dialerTeam) {
        const dialerTeams = await this.agentTeams(this._security.authenticatedMemberId);
        return dialerTeams.includes(dialerTeam);
    }

    async call(leadIds, dialerTeamKey, phoneNumber, agentIds) {
        const payload = { leadIds, dialerTeamKey, phoneNumber, agentIds };
        return await this._api.post('dialer/call', payload);
    }
}
