import { Api } from 'common/server';
import { I18n } from 'common/i18n';

export const SCRIPT_TYPE = {
    Phone: 'phone',
    InPerson: 'in-person',

    all: () => {
        return [SCRIPT_TYPE.Phone, SCRIPT_TYPE.InPerson ];
    },

    allWithIcons: () => {
        return [
            { key: SCRIPT_TYPE.Phone, icon: 'fa-duotone fa-solid fa-phone' },
            { key: SCRIPT_TYPE.InPerson, icon: 'fa-duotone fa-solid fa-house-user' },
        ];
    },
};

export const SCRIPT_PERSON = {
    First: 'first',
    Third: 'third',

    all: () => {
        return [SCRIPT_PERSON.First, SCRIPT_PERSON.Third ];
    },

    allWithIcons: () => {
        return [
            { key: SCRIPT_PERSON.First, icon: 'fa-duotone fa-solid fa-user' },
            { key: SCRIPT_PERSON.Third, icon: 'fa-duotone fa-solid fa-users' },
        ];
    },
};

export class LeadScripts {
    static inject = [Api, I18n];
    _api;
    _i18n;

    constructor(api, i18n) {
        this._api = api;
        this._i18n = i18n;
    }

    async all() {
        const all = await this._api.get('lead-scripts');
        all.forEach(s => {
            s.scriptTypeName = this._i18n.tr(`lead:script-type-${s.scriptType}`);
            s.scriptPersonName = this._i18n.tr(`lead:script-person-${s.scriptPerson}`);
        });
        return all;
    }

    async save(payload) {
        return await this._api.post('lead-scripts', payload);
    }

    async delete(id) {
        return await this._api.delete(`lead-scripts/${encodeURIComponent(id)}`);
    }

    async copy(id) {
        return await this._api.post(`lead-scripts/${encodeURIComponent(id)}/copy`);
    }

    async setDefault(id) {
        return await this._api.put(`lead-scripts/default/${encodeURIComponent(id)}`);
    }

    async filterOptions() {
        return await this._api.get('lead-scripts/filter-options');
    }

    async saveFilter(id, filter) {
        return await this._api.put(`lead-scripts/${encodeURIComponent(id)}/filters`, filter);
    }

    async scriptForLead(leadId, type, person) {
        let url = `lead-scripts/for-lead/${encodeURIComponent(leadId)}`;
        const params = [];
        if (type) params.push(`type=${encodeURIComponent(type)}`);
        if (person) params.push(`person=${encodeURIComponent(person)}`);
        if (params.length) url += `?${params.join('&')}`;
        return await this._api.get(url);
    }

    async sampleScript(scriptId) {
        return await this._api.get(`lead-scripts/for-script/${encodeURIComponent(scriptId)}`);
    }
}
