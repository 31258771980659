import { Api } from 'common/server';

export class Bonuses {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async personalSummary(id, date) {
        return await this._api.get(`bonus/monthly/personal/${encodeURIComponent(id)}?date=${encodeURIComponent(date.format('L'))}`);
    }

    async downlinePersonal(id, view, asOfDate) {
        return await this._api.get(`bonus/monthly/personal/${encodeURIComponent(id)}/${encodeURIComponent(view)}?date=${encodeURIComponent(asOfDate)}`);
    }

    async agencySummary(id, date) {
        return await this._api.get(`bonus/monthly/agency/${encodeURIComponent(id)}?date=${encodeURIComponent(date.format('L'))}`);
    }
}
