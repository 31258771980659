import { Api } from 'common/server';
import environment from '../../config/environment.json';

export class Files {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async list(searchOn = null) {
        let url = 'file';
        if (searchOn) url += `?searchOn=${encodeURIComponent(searchOn)}`;
        const files = await this._api.get(url);
        files.forEach(f => {
            f.isImage = fileIsImage(f.mediaType);
            f.url = `${environment.api}/api/file/${f.id}`;
        });
        return files;
    }

    async delete(fileId) {
        return this._api.delete(`file/${encodeURIComponent(fileId)}`);
    }

    async rename(fileId, displayName) {
        await this._api.put(`file/${encodeURIComponent(fileId)}/rename?displayName=${encodeURIComponent(displayName)}`);
    }
}

export function fileIcon(mediaType) {
    if (mediaType.indexOf('image') === 0) return 'fa-duotone fa-file-image';
    if (mediaType.indexOf('video') === 0) return 'fa-duotone fa-file-video';
    switch (mediaType) {
        case 'application/pdf': return 'fa-duotone fa-file-pdf';
        case 'application/zip': return 'fa-duotone fa-file-zipper';
        case 'vnd.ms-excel': return 'fa-duotone fa-file-powerpoint';
        case 'application/zip': return 'fa-duotone fa-file-zipper';
        case 'text/html': return 'fa-duotone fa-file-code';
        default:
            if (mediaType.indexOf('vnd.openxmlformats-officedocument.spreadsheetml') >= 0) return 'fa-duotone fa-file-excel';
            if (mediaType.indexOf('vnd.openxmlformats-officedocument.presentationml') >= 0) return 'fa-duotone fa-file-powerpoint';
            if (mediaType.indexOf('vnd.openxmlformats-officedocument.wordprocessingml') >= 0) return 'fa-duotone fa-file-word';
            return 'fa-duotone fa-file';
    }
}

export function fileIsImage(mediaType) {
    if (mediaType.indexOf('image') === 0) return true;
    return false;
}
