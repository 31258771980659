import { Api } from 'common/server';
import { Carriers } from 'services/carriers';
import { Dashboards } from 'services/dashboards';
import { I18n } from 'common/i18n';
import { Security } from 'common/security';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Config } from 'services/config';
import { Notifier } from 'common/ui';
import { c } from 'common/common';

export const MENU_AREA = {
    members: 'members',
    admin: 'admin',
    bigButtons: 'big-buttons',
    onboarding: 'onboarding',
};

export const MENU_TYPE = {
    carriers: 'Carriers',
    dashboards: 'Dashboards',
    custom: 'Custom',
};

export class Menus {
    static inject = [Api, Carriers, Dashboards, I18n, Security, EventAggregator, Config, Notifier];
    _api;
    _carriers;
    _dashboards;
    _i18n;
    _security;
    _ea;
    _config;
    _notifier;

    _menuByArea = {};
    _handlers = [];

    constructor(api, carriers, dashboards, i18n, security, ea, config, notifier) {
        this._api = api;
        this._carriers = carriers;
        this._dashboards = dashboards;
        this._i18n = i18n;
        this._security = security;
        this._ea = ea;
        this._config = config;
        this._notifier = notifier;
    }

    async initialize() {
        this._handlers.push(this._ea.subscribe(c.EventKeys.menu.reset, (data) => {
            this._menuByArea[data.menu] = undefined;
            const fireEvent = data ? data.fireEvent : false;
            this._initialize(data.menu, fireEvent);
        }));
        await this._initialize(MENU_AREA.members);
    }

    async _initialize(menuArea, fireEvent) {
        try {
            this._menuByArea[menuArea] = await this._initializeArea(menuArea);
            if (fireEvent) this._ea.publish(c.EventKeys.menu.updated, { area: menuArea });
        } catch (err) {
            console.log(err);
        }
    }

    async _initializeArea(area) {
        const menu = this._loadByArea(area);
        if (!menu) return undefined;
        menu.forEach(async(menuItem) => {
            switch (menuItem.type) {
                case MENU_TYPE.custom:
                    if (!menuItem.items || !menuItem.items.length) return;
                    menuItem.items.forEach(async(subItem) => {
                        // handle non typical sub menus
                    });
                    return;
                case MENU_TYPE.carriers:
                    menuItem.type = MENU_TYPE.custom;
                    if (!menuItem.items) menuItem.items = [];
                    const carriers = await this._loadCarriers();
                    const carrierSubMenu = [];
                    carriers.forEach(carrier => {
                        const csm = {
                            type: MENU_TYPE.custom,
                            href: `#/members/carriers/${encodeURIComponent(carrier.slug)}`,
                            name: `${carrier.name}${!carrier.isActive ? ' (inactive)' : ''}`,
                        };
                        if (!carrier.isActive) csm.ifRole = this._carriers.ADMIN_ROLES;
                        carrierSubMenu.push(csm);
                    });
                    menuItem.items = [...carrierSubMenu, ...menuItem.items];
                    return;
                case MENU_TYPE.dashboards:
                    menuItem.type = MENU_TYPE.custom;
                    if (!menuItem.items) menuItem.items = [];
                    const dashboards = await this._loadDashboards();
                    const dashboardsSubMenu = [];
                    const dashboardGroups = [];
                    dashboards.forEach(d => {
                        const group = d.group || d.key;
                        if (dashboardGroups.indexOf(group) >= 0) return;
                        dashboardGroups.push(d.group);
                    });

                    dashboardGroups.forEach(g => {
                        const groupDashboards = dashboards.filter(x => x.group === g);
                        const hasSubMenu = groupDashboards[0].showHeading;
                        const dashboardWithSub = hasSubMenu
                            ?
                                {
                                    type: MENU_TYPE.custom,
                                    code: g,
                                    href: undefined,
                                    name: this._i18n.tr(`dashboard-nav-${g}`),
                                    items: [],
                                }
                            : undefined;
                        groupDashboards.forEach(dashboard => {
                            if (!dashboard.layout || !dashboard.layout.length) {
                                // Display the empty ones for the dashboard admins
                                if (!dashboard.canEdit) return;
                            }
                            const dashboardItem = {
                                type: MENU_TYPE.custom,
                                href: `#/members/dashboard/${encodeURIComponent(dashboard.key)}`,
                                name: dashboard.name || this._i18n.tr(`dashboard-nav-${dashboard.key}`),
                                icon: dashboard.icon,
                            }
                            if (dashboardWithSub) {
                                dashboardWithSub.items.push(dashboardItem);
                            } else {
                                dashboardsSubMenu.push(dashboardItem);
                            }
                        });
                        if (dashboardWithSub) {
                            dashboardsSubMenu.push(dashboardWithSub);
                        }
                    });

                    const addDashboard = {
                        type: MENU_TYPE.custom,
                        href: `#/members/dashboard/add-personal-dashboard`,
                        name: this._i18n.tr('dashboard-nav-add-personal-dashboard'),
                        icon: 'fas fa-user-plus',
                    };
                    dashboardsSubMenu.push(addDashboard);
                    menuItem.items = [...dashboardsSubMenu, ...menuItem.items];
                    return;
                default:
                    return;
            }
        });
        return menu;
    }

    async _loadCarriers() {
        try {
            return await this._carriers.all();
        } catch (err) {
            console.log(err);
        }
    }

    async _loadDashboards() {
        try {
            return await this._dashboards.all();
        } catch (err) {
            console.log(err);
        }
    }

    dispose() {
        this._menuByArea[MENU_AREA.members] = undefined;
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    async byArea(area) {
        if (!this._menuByArea[area]) this._menuByArea[area] = await this._initializeArea(area);
        return this._menuByArea[area];
    }

    _loadByArea(area) {
        const menus = this._config.value('menus');
        const areaMenu = menus[area];
        return JSON.parse(areaMenu);
    }

    async addToMobileMenu(id = null, type, code, title, description, href, icon, metadata) {
        const payload = {
            id,
            type,
            code,
            title,
            description,
            href,
            icon,
            metadata,
        };
        const data = await this._api.post('menu/mobile', payload);
        await this._resetMenu(MENU_AREA.bigButtons);
        this._notifier.success('mobile-menu-updated');
        return data;
    }

    async removeFromMobileMenu(id, type, code) {
        if (!id) {
            const mm = this.mobileMenu(id, type, code);
            id = mm.id;
        }
        const data = await this._api.delete(`menu/mobile/${encodeURIComponent(id)}`);
        await this._resetMenu(MENU_AREA.bigButtons);
        this._notifier.success('mobile-menu-updated');
        return data;
    }

    async setMobileMenuOrdering(ids) {
        const data = await this._api.post('menu/mobile/reorder', ids);
        await this._resetMenu(MENU_AREA.bigButtons);
        this._notifier.success('mobile-menu-updated');
        return data;
    }

    async _resetMenu(area) {
        await this._config.initialize();
        this._initialize(area, true);
    }

    mobileMenu(id, type, code) {
        try {
            const bigButtonsMenu = this._loadByArea(MENU_AREA.bigButtons);
            const item = id
                ? bigButtonsMenu.find(x => x.id === id)
                : bigButtonsMenu.find(x => x.type === type && x.code === code);
            return item;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    isOnMobileMenu(type, code) {
        try {
            const bigButtonsMenu = this._loadByArea(MENU_AREA.bigButtons);
            const item = bigButtonsMenu.find(x => x.type === type && x.code === code);
            return item !== undefined;
        } catch (err) {
            console.log(err);
            return false;
        }
    }
}
