import { Api } from 'common/server';
import { TIME_PERIOD } from 'common/constants';

export const KPI_FOR = {
    Personal: 'personal',
    Team: 'team'
};

export const KPI_PERIOD = {
    Weekly: 'weekly',
    Monthly: 'monthly',

    toTimePeriod: (kpiPeriod) => {
        switch (kpiPeriod) {
            case KPI_PERIOD.Weekly: return TIME_PERIOD.WTD;
            case KPI_PERIOD.Monthly: return TIME_PERIOD.MTD;
            default: return TIME_PERIOD.MTD;
        }
    },
};

export class Kpis {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async templates() {
        return await this._api.get('kpi-template');
    }

    async updateTemplate(kpi, goal) {
        const model = { goal };
        return await this._api.put(`kpi-template/${encodeURIComponent(kpi.kpiType)}/${encodeURIComponent(kpi.kpiFor)}/${encodeURIComponent(kpi.kpiPeriod)}`, model);
    }

    async activateTemplate(kpi, isActive) {
        const url = `kpi-template/${encodeURIComponent(kpi.kpiType)}/${encodeURIComponent(kpi.kpiFor)}/${encodeURIComponent(kpi.kpiPeriod)}/${isActive ? 'activate' : 'deactivate'}`;
        return await this._api.post(url);
    }

    async health(memberId, asOfDate, productionType) {
        let url = `kpi/${encodeURIComponent(memberId)}/health`;
        const qs = [];
        if (asOfDate) qs.push(`asOfDate=${encodeURIComponent(asOfDate)}`);
        if (productionType) qs.push(`productionType=${encodeURIComponent(productionType)}`);
        if (qs.length) url += `?${qs.join('&')}`;
        return await this._api.get(url);
    }

    async allAvailable() {
        return await this._api.get('kpi');
    }

    async progress(kpi, asOfDate = undefined) {
        let url = `kpi/${encodeURIComponent(kpi.kpiType)}/${encodeURIComponent(kpi.kpiFor)}/${encodeURIComponent(kpi.kpiPeriod)}`;
        if (asOfDate) url += `?asOfDate=${encodeURIComponent(asOfDate)}`;
        return await this._api.get(url);
    }

    async progress2(kpi, asOfDate = undefined, memberId = undefined) {
        let url = 'kpi';
        const qs = [];
        if (asOfDate) qs.push(`asOfDate=${encodeURIComponent(asOfDate)}`);
        if (memberId) qs.push(`memberId=${encodeURIComponent(memberId)}`);
        if (qs.length) url += `?${qs.join('&')}`;
        return await this._api.post(url, kpi);
    }
}
