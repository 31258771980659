import { Api } from 'common/server';
import { Security } from 'common/security';

export class Mentors {
    static inject = [Api, Security];
    _api;
    _security;

    constructor(api, security) {
        this._api = api;
        this._security = security;
    }

    async all() {
        return await this._api.get('mentor');
    }

    async forAgent(memberId) {
        return await this._api.get(`mentor/${encodeURIComponent(memberId)}`);
    }

    async assignMentor(memberId, mentorMemberId) {
        let url = `mentor/${encodeURIComponent(memberId)}/assign/${encodeURIComponent(mentorMemberId)}`;
        return await this._api.post(url);
    }

    async removeMentor(memberId) {
        return await this._api.delete(`mentor/${encodeURIComponent(memberId)}`);
    }


    async mentees(memberId) {
        if (!memberId) memberId = this._security.authenticatedMemberId;
        return await this._api.get(`mentor/${encodeURIComponent(memberId)}/mentees`);
    }
}
