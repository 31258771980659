import { Api } from 'common/server';

export const IMPORT_TYPE = {
    Leads: 'leads',
    Policies: 'policies'
};

export class Imports {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async columnTypes(importType) {
        return await this._api.get(`import/definition/column-types/${importType}`);
    }

    async saveDefinition(payload) {
        return await this._api.post('import/definition', payload);
    }
}
