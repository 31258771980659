import { Api } from 'common/server';

export class Badges {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async all(badgeType = 'agent') {
        const url = `badge/${encodeURIComponent(badgeType)}/definition`;
        return await this._api.get(url);
    }

    async assigned(badgeType, code) {
        const url = `badge/${encodeURIComponent(badgeType)}/assigned/${encodeURIComponent(code)}`;
        return await this._api.get(url);
    }

    async for(badgeType, memberId = null) {
        let url = `badge/${encodeURIComponent(badgeType)}`;
        const qs = [];
        if (memberId) qs.push(`memberId=${encodeURIComponent(memberId)}`);
        if (qs.length) url += `?${qs.join('&')}`;
        return await this._api.get(url);
    }

    async unassigned(badgeType, memberId) {
        let url = `badge/${encodeURIComponent(badgeType)}/unassigned`;
        const qs = [];
        if (memberId) qs.push(`memberId=${encodeURIComponent(memberId)}`);
        if (qs.length) url += `?${qs.join('&')}`;
        return await this._api.get(url);
    }

    async assign(memberId, code) {
        let url = `badge/${encodeURIComponent(code)}/assign`;
        const model = { memberId };
        return await this._api.post(url, model);
    }

    async unassign(memberId, code) {
        let url = `badge/${encodeURIComponent(code)}/unassign`;
        const model = { memberId };
        return await this._api.post(url, model);
    }

    async saveDefinition(badgeType, code, name, color, icon, ordering) {
        let url = `badge/${encodeURIComponent(badgeType)}/definition`;
        const model = { code, name, color, icon, ordering };
        return await this._api.post(url, model);
    }

    async deleteDefinition(code) {
        let url = `badge/${encodeURIComponent(code)}/definition`;
        return await this._api.delete(url);
    }
}
