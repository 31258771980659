import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'common/server';
import { I18n } from 'common/i18n';

export class Content {
    static inject = [EventAggregator, Api, I18n];
    _ea;
    _api;
    _i18n;

    constructor(ea, api, i18n) {
        this._ea = ea;
        this._api = api;
        this._i18n = i18n;
    }

    async allByType(type) {
        return await this._api.get(`content/ui-widget/list/${encodeURIComponent(this._i18n.defaultLocale)}/type/${encodeURIComponent(type)}`);
    }

    async delete(id) {
        return await this._api.delete(`content/ui-widget/${encodeURIComponent(id)}`);
    }

    async emailKeys() {
        return await this._api.get('content/email');
    }

    async emailSettings(key) {
        return await this._api.get(`content/email/${encodeURIComponent(key)}/settings`);
    }

    async saveEmailSettings(key, settings) {
        return await this._api.post(`content/email/${encodeURIComponent(key)}/settings`, settings);
    }

    async sendTestEmail(key) {
        return await this._api.post(`content/email/${encodeURIComponent(key)}/test`);
    }

    async uiWidget(key) {
        let locale = this._i18n.currentLocale;
        return await this._api.get(`content/ui-widget/${encodeURIComponent(key)}/${encodeURIComponent(locale)}`);
    }

    async saveUiTerm(namespace, code, value, language) {
        language = language ?? this._i18n.defaultLocale;
        const payload = { namespace, code, language, translation: value };
        return await this._api.post('content/ui-term', payload);
    }
}
